<template>
    <el-dialog
        v-loading="loading"
        title="日志查看"
        :visible.sync="dialogVisible"
        width="70%"
    >

        <el-form
            label-width="120px"
        >
            <div
                v-for="(item, index) in model"
                :key="index"
            >
                <el-form-item :label="index">
                    <code>{{item}}</code>
                </el-form-item>
            </div>
        </el-form>
    </el-dialog>
</template>

<script>
import LogService from '@admin/services/LogService'
import flatry from '@admin/utils/flatry'
export default {
  name: 'LogView',
  data () {
    return {
      dialogVisible: false,
      model: null,
      loading: true
    }
  },

  methods: {
    async view (id) {
      this.model = null
      this.dialogVisible = true
      const { data } = await flatry(LogService.view(id))
      if (data) {
        this.model = data.data
        console.log(this.model)
      }
      this.loading = false
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
