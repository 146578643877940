<template>
  <div class="box">
    <div class="box-toolbar">
      <div class="box-item">
        <el-input
          v-model="url"
          placeholder="请输入请求地址"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-input
          placeholder="请输入描述"
          v-model="des"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      :stripe="true"
      :data="lists"
      height="75vh"
    >
      <el-table-column
        width="90"
        class-name="text-mono"
        prop="id"
        label="序号"
        fixed
      >
      </el-table-column>
      <el-table-column
        prop="des"
        label="操作描述"
      >
      </el-table-column>
      <el-table-column
        width="150"
        prop="type"
        class-name="text-mono"
        label="操作类型"
      >
      </el-table-column>
      <el-table-column
        prop="action"
        label="操作方法"
      >
      </el-table-column>

      <el-table-column
        prop="user.name"
        label="操作用户"
      >
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="操作时间"
      >
      </el-table-column>
      <!-- <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            plain
            size='mini'
            type="primary"
            @click="viewLog(scope.row)"
          >查看</el-button>
        </template>

      </el-table-column> -->
    </el-table>
    <page
      :pageSize="limit"
      :count="count"
      @change="pageChange"
      @changeSize="changeSize"
    >
    </page>
    <viewLogiIndex ref="view"/>
  </div>
</template>

<script>
import LogService from '@admin/services/LogService'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'
import viewLogiIndex from './components/view'

export default {
  components: { Page, viewLogiIndex },
  data () {
    return {
      loading: true,
      function: function (param) {
        return LogService.all(param)
      },
      url: '',
      des: ''
    }
  },
  mixins: [pagination],
  methods: {
    async searchGo () {
      this.page = 1
      let param = {
        url: this.url,
        des: this.des
      }
      this.where = param
      await this.getList(param)
    },
    viewLog (item) {
      this.$refs.view.view(item.id)
    }
  }
}
</script>

<style scoped>
</style>
